import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { getMarketplaceEntities, getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { addToWishList } from '../WishListPage/WishListPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Page,
  SectionHero,
  SectionShopNow,
  SectionListings,
  SectionStatic,
  SectionUsers,
  SectionHowItWorks,
  SectionReviews,
  SectionStrava,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import classNames from 'classnames';

import facebookImage from '../../assets/reveloFacebook-1200x630.jpg';
import twitterImage from '../../assets/reveloTwitter-600x314.jpg';
import firstSectionImage from './images/section-image-1.jpg';
import css from './LandingPage.module.css';

const { UUID } = sdkTypes;

export const LandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    history,
    location,
    listings,
    listingsLoaded,
    fetchListingsInProgress,
    fetchListingsError,
    categoryListings,
    categoryListingsLoaded,
    fetchCategoryListingsInProgress,
    fetchCategoryListingsError,
    users,
    usersLoaded,
    fetchUsersInProgress,
    fetchUsersError,
    reviews,
    reviewsLoaded,
    fetchReviewsInProgress,
    fetchReviewsError,
    currentUser,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionShopNow />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionListings)}>
                <SectionListings
                  listings={listings}
                  listingsLoaded={listingsLoaded}
                  fetchListingsInProgress={fetchListingsInProgress}
                  fetchListingsError={fetchListingsError}
                  currentUser={currentUser}
                  onAddToWishList={onAddToWishList}
                  currentWishListListingId={currentWishListListingId}
                  addToWishListInProgress={addToWishListInProgress}
                  addToWishListError={addToWishListError}
                  titleMsgId="LandingPage.newin.title"
                  textMsgId="LandingPage.newin.subTitle"
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentStatic}>
                <SectionStatic
                  titleMsgId="LandingPage.firstSectionTitle"
                  textMsgId="LandingPage.firstSectionText"
                  imageLink={firstSectionImage}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionListings)}>
                <SectionListings
                  listings={categoryListings}
                  listingsLoaded={categoryListingsLoaded}
                  fetchListingsInProgress={fetchCategoryListingsInProgress}
                  fetchListingsError={fetchCategoryListingsError}
                  currentUser={currentUser}
                  onAddToWishList={onAddToWishList}
                  currentWishListListingId={currentWishListListingId}
                  addToWishListInProgress={addToWishListInProgress}
                  addToWishListError={addToWishListError}
                  titleMsgId="LandingPage.jerseys.title"
                  textMsgId="LandingPage.jerseys.subTitle"
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionHowItWorks)}>
                <SectionHowItWorks
                  reviews={reviews}
                  reviewsLoaded={reviewsLoaded}
                  fetchReviewsInProgress={fetchReviewsInProgress}
                  fetchReviewsError={fetchReviewsError}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentStatic}>
                <SectionStatic
                  titleMsgId="LandingPage.secondSectionTitle"
                  textMsgId="LandingPage.secondSectionText"
                  bgColor="rgb(17, 90, 54)"
                  link={config.siteStripeClimatePage}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionUsers)}>
                <SectionUsers
                  users={users}
                  usersLoaded={usersLoaded}
                  fetchUsersInProgress={fetchUsersInProgress}
                  fetchUsersError={fetchUsersError}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionStrava)}>
                <SectionStrava />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionReviews)}>
                <SectionReviews
                  reviews={reviews}
                  reviewsLoaded={reviewsLoaded}
                  fetchReviewsInProgress={fetchReviewsInProgress}
                  fetchReviewsError={fetchReviewsError}
                />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer showNewsletter />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    listingIds,
    fetchListingsInProgress,
    fetchListingsError,
    fetchCategoryListingsInProgress,
    fetchCategoryListingsError,
    categoryListingIds,
    userIds,
    fetchUsersInProgress,
    fetchUsersError,
    reviews,
    fetchReviewsInProgress,
    fetchReviewsError,
  } = state.LandingPage;
  const {
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  } = state.WishListPage;
  const { currentUser } = state.user;

  // Get listings
  const listings = getListingsById(state, listingIds);
  const listingsLoaded = listings && listings.length > 0;

  // Get category listings
  const categoryListings = getListingsById(state, categoryListingIds);
  const categoryListingsLoaded = categoryListings && categoryListings.length > 0;

  // Get users
  const users = userIds.map(id => {
    return getMarketplaceEntities(state, [{ type: 'user', id: new UUID(id) }])?.[0];
  });
  const usersLoaded = users && users.length > 0;

  // Get reviews
  const reviewsLoaded = reviews && reviews.length > 0;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    listings,
    listingsLoaded,
    fetchListingsInProgress,
    fetchListingsError,
    categoryListings,
    categoryListingsLoaded,
    fetchCategoryListingsInProgress,
    fetchCategoryListingsError,
    users,
    usersLoaded,
    fetchUsersInProgress,
    fetchUsersError,
    reviews,
    reviewsLoaded,
    fetchReviewsInProgress,
    fetchReviewsError,
    currentUser,
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
  };
};

const mapDispatchToProps = dispatch => ({
  onAddToWishList: listingId => dispatch(addToWishList(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
